import { LayoutRegistry } from '@nxweb/core/router';

import { BlankLayout } from './blank';
import { DefaultLayout } from './default';
import { LayoutProvider } from './provider';

// ** All of the available layouts
const Layouts = new LayoutRegistry({
  blank: BlankLayout,
  default: DefaultLayout
});

export { Layouts, LayoutProvider };
